<template>
    <div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "SystemWrapper"
    }
</script>

<style scoped>

</style>