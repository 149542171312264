<template>
    <div class="columns">
        <div class="content" v-if="contentStatus">
            <div class="panel panel-body">
                <Form v-model="formData" ref="form" />
                <a href="#" :title="text.save" class="btn btn-primary btn-smyspace" @click="save($event)" @keyup.enter="save($event)">{{text.save}}</a> <router-link :to="{ name: 'AdminBusinessUnitList' }" :title="text.cancel" class="btn btn-tertiary btn-noyspace">{{text.cancel}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";
    import {addBusinessUnit} from "@/shared/dataservice";

    import Form from "@/components/form/form";

    export default {
        name: 'BusinessUnitNew',
        components: {Form},
        mixins: [formWrapperMixin],
        data() {
            return {
                businessUnitData: {},
                contentStatus: true,
                formData: [
                    {
                        id: 1,
                        type: 'text',
                        name: 'businessunit_name',
                        label: 'Department Name',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'string',
                            required: true,
                            min: 3,
                            max: 127
                        }
                    },
                ]

            }
        },
        computed: {
            text() {
                return lang
            }
        },
        created() {
        },
        methods: {
            async save(e){
                e.preventDefault();

                log(["Save triggered by wrapper"]);

                if(this.$refs.form.save()){

                    // save updated value into helpData
                    this.businessUnitData.name = this.getFormDataValueByName(this.formData, "businessunit_name");

                    log(["Form data help", this.businessUnitData.name]);

                    let result = await addBusinessUnit(this.businessUnitData);

                    if(result.status === 201){
                        this.$router.push( { name: 'AdminBusinessUnitList', params: { message: 'added'} } );
                    }
                    else
                    {
                        log(["Form save result no ok"]);
                    }
                }
                else
                {
                    // TO DO SHOW ERROR
                    log(["Form is NOT ok to be saved"]);
                }
            }
        }
    }
</script>