// define a mixin object
import {log, showDefaultServerErrorMessage} from "@/shared/utils";
import {getUserList, createNewUser, getUserByID, saveUserByID, enableUserByID, disableUserByID, getBusinessUnits, getUserRoles} from "@/shared/dataservice";

export const userActionsMixin = {
    data() {
        return {
            businessUnits: [],
            userRoles: [],
            userData: {}
        }
    },
    methods: {
        async loadUserList(page = 1) {
            let result = await getUserList(page);

            log(["Loaded USER list", page, result]);

            this.userTableData = result.data.data;
            this.userTablePagination = result.data.meta;
        },
        async loadUserByID(id) {
            log(["Loading user data", id]);

            let result = await getUserByID(id)
            this.userData = result.data.data;
        },
        async saveUserByID(id, data) {
            log(["Loading user data", id, data]);
            return saveUserByID(id, data);
        },
        async addUser(data) {
            log(["Add user data", data]);
            return createNewUser(data);
        },
        /**
         * ACTIONS
         */
        disableUser: async function (id) {
            let result = await disableUserByID(id);

            log(["Disable user", id, result]);

            if(result.status === 200){
                return true;
            }
            else
            {
                showDefaultServerErrorMessage("Disable user");
                return false;
            }
        },
        enableUser: async function (id) {
            let result = await enableUserByID(id);

            log(["Enable user", id, result]);

            if(result.status === 200){
                return true;
            }
            else
            {
                showDefaultServerErrorMessage("Enable user");
                return false;
            }
        },

        /**
         * Other
         */
        async initBusinessUnits(){
            await this.loadBusinessUnits();
            this.setFormDataOptionsByName(this.formData, "businessUnitIDs", this.parseBusinessUnitsToSelectOptions(await this.businessUnits));
        },
        async loadBusinessUnits() {
            let result = await getBusinessUnits();
            log(["Got user business units", result]);
            this.businessUnits = result.data.data;
        },
        parseBusinessUnitsToSelectOptions(businessUnits) {
            return [
                { value: '', text: this.text.select_option,},
                ...businessUnits.map(function(row) {
                    return {
                        value: row.id,
                        text: row.name,
                    };
                })
            ];
        },
        async initUserRoles(){
            await this.loadUserRoles();
            this.setFormDataOptionsByName(this.formData, "roles", this.parseUserRolesToSelectOptions(this.userRoles));
        },
        async loadUserRoles() {
            let result = await getUserRoles();
            log(["Got user roles", result]);
            this.userRoles = result.data.data;
        },
        parseUserRolesToSelectOptions(userRoles) {
            return [
                { value: '', text: this.text.select_option,},
                { value: 'User', text: 'User',},
                ...userRoles.map(function(row) {
                    return {
                        value: row.roleName,
                        text: row.roleName,
                    };
                })
            ];
        },
    },
}