<template>
    <div>
        <router-link :to="{ name: 'AdminBusinessUnitNew' }" :title="text.cta_new_business_unit" class="btn">{{text.cta_new_business_unit}}</router-link>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th>{{text.business_unit}}</th>
                    <th>{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                    <tr v-for="row in activeTableData" :key="row.id">
                        <td>
                            <router-link :to="{ name: 'AdminBusinessUnitEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action" v-if="!row.archived">{{row.name}}</router-link>
                            <span class="archived" v-if="row.archived">{{row.name}}</span>
                        </td>
                        <td>
                            <EditAction :routeName="'AdminBusinessUnitEdit'" :routeParams="{ 'id' : row.id }" :linkName="row.name" v-if="!row.archived" />
                            <ArchiveAction :linkName="row.name" @actionCalled="archiveBusinessUnit(row.id, 'loadBusinessUnitList')" v-if="!row.archived" />
                            <RestoreAction :linkName="row.name" @actionCalled="restoreBusinessUnit(row.id, 'loadBusinessUnitList')" v-if="row.archived" />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="2">No rows to display</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";
    import {getBusinessUnits, archiveBusinessUnitByID, restoreBusinessUnitByID} from "@/shared/dataservice";
    import {log} from "@/shared/utils";
    import {showDefaultServerErrorMessage} from "@/shared/utils";

    import EditAction from "@/components/actionlinks/edit";
    import ArchiveAction from "@/components/actionlinks/archive";
    import RestoreAction from "@/components/actionlinks/restore";

    export default {
        name: 'BusinessUnit',

        components: {EditAction, ArchiveAction, RestoreAction},
        data() {
            return {
                activeTableData: []
            }
        },
        computed: {
            text() {
                return lang;
            },
            message() {
                return this.$route.params.message
            }
        },
        methods: {
            async loadBusinessUnitList() {
                let result = await getBusinessUnits();

                log(["Loaded help", result.data.data]);

                this.activeTableData = result.data.data;
            },
            showMessage() {
                if(this.message === "saved"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_business_unit_edit_saved
                    });
                }
                else if(this.message === "added"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_business_unit_added
                    });
                }
            },
            archiveBusinessUnit: async function (id, successMethodName) {
                let result = await archiveBusinessUnitByID(id);

                log(["Archive BusinessUnit", id, result]);

                if(result.status === 200){
                    this[successMethodName]();
                }
                else
                {
                    showDefaultServerErrorMessage("Archive Business Unit");
                }
            },
            restoreBusinessUnit: async function (id, successMethodName) {
                let result = await restoreBusinessUnitByID(id);

                log(["Restore BusinessUnit", id, result]);

                if(result.status === 200){
                    this[successMethodName]();
                }
                else
                {
                    showDefaultServerErrorMessage("Restore Business Unit");
                }
            },
        },
        async mounted() {
            await this.loadBusinessUnitList();
            this.showMessage();
        }
    }
</script>