<template>
    <div>
        <router-link :to="{ name: 'AdminUserNew' }" :title="text.create_new_user" class="btn">{{text.create_new_user}}</router-link>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th class="w-4/12">{{text.name}}</th>
                    <th class="w-3/12">{{text.business_unit}}</th>
                    <th class="w-3/12">{{text.user_role}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="userTableData.length > 0">
                <tr v-for="row in userTableData" :key="row.id" :class="[{'disabled' : row.disabled}]">
                    <td>
                        <router-link :to="{ name: 'AdminUserEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action" v-if="!row.disabled">{{row.name}}</router-link>
                        <span class="disabled-action" v-else>{{row.name}}</span>
                    </td>
                    <td>{{getUserBusinessUnitString(row.assignedBusinessUnits)}}</td>
                    <td>{{getUserRoleString(row.roles)}}</td>
                    <td>
                        <EditAction :routeName="'AdminUserEdit'" :routeParams="{ 'id' : row.id }" :linkName="row.name" v-if="!row.disabled" />
                        <a href="#" :title="text.disable_user" @click="onDisableUser($event, row.id);" @keyup.enter="onDisableUser($event, row.id);" v-if="!row.disabled">{{text.disable_user}}</a>
                        <a href="#" :title="text.enable_user" @click="onEnableUser($event, row.id);" @keyup.enter="onEnableUser($event, row.id);" v-else>{{text.enable_user}}</a>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="3">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="userTablePagination.totalPages > 0" :pagination="userTablePagination" @go-previous="refreshUserList" @go-next="refreshUserList" />
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {userActionsMixin} from "@/views/mixins/userActions";

    import Pagination from "@/components/pagination";
    import EditAction from "@/components/actionlinks/edit";

    export default {
        name: 'AdminUserList',
        components: {Pagination, EditAction},
        mixins: [formatContentMixin,userActionsMixin],
        data() {
            return {
                userTableData: [],
                userTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
            message() {
                return this.$route.params.message
            }
        },
        methods: {
            getUserBusinessUnitString(businessUnits){
                log(["getting business units", businessUnits, businessUnits.filter(businessUnit => businessUnit.name)]);
                return businessUnits.map(businessUnit => businessUnit.name).join();
            },
            getUserRoleString(roles){
                return roles.includes("Admin") ? lang.role_admin : lang.role_user;
            },
            async refreshUserList(page = 1){
                await this.loadUserList(page);
            },
            showMessage() {
                if(this.message === "saved"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_user_edit_saved
                    });
                }
                else if(this.message === "added"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_user_added
                    });
                }
            },
            async onDisableUser(e, id){
                e.preventDefault();
                if(await this.disableUser(id)){
                    this.refreshUserList(this.userTablePagination);
                }
            },
            async onEnableUser(e, id){
                e.preventDefault();
                if(await this.enableUser(id)){
                    this.refreshUserList(this.userTablePagination);
                }
            }
        },
        async mounted() {
            this.refreshUserList(1);
            this.showMessage();
        }
    }
</script>