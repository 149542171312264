<template>
    <div>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th>{{text.page_name}}</th>
                    <th>{{text.page_alias}}</th>
                    <th>{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                    <tr v-for="row in activeTableData" :key="row.id">
                        <td><router-link :to="{ name: 'AdminInPageHelpEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                        <td>{{row.alias}}</td>
                        <td>
                            <router-link :to="{ name: 'AdminInPageHelpEdit', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.name">{{text.edit}}</router-link>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="3">No rows to display</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";
    import {getAllInPageHelp} from "@/shared/dataservice";
    import {log} from "@/shared/utils";

    export default {
        name: 'InPageHelp',
        data() {
            return {
                activeTableData: []
            }
        },
        computed: {
            text() {
                return lang;
            },
            message() {
                return this.$route.params.message
            }
        },
        methods: {
            async loadInPageHelpList() {
                let result = await getAllInPageHelp();

                log(["Loaded help", result.data.data]);

                this.activeTableData = result.data.data;
            },
            showMessage() {
                if(this.message === "saved"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_in_page_help_edit_saved
                    });
                }
            }
        },
        async mounted() {
            await this.loadInPageHelpList();
            this.showMessage();
        }
    }
</script>