<template>
    <div class="columns">
        <div class="content body-content" v-if="contentStatus">
            <div class="panel panel-body">
                <Form v-model="formData" ref="form" />
                <a href="#" :title="text.save" class="btn btn-primary btn-smyspace" @click="save($event)" @keyup.enter="save($event)">{{text.save}}</a> <router-link :to="{ name: 'AdminUserList' }" :title="text.cancel" class="btn btn-tertiary btn-noyspace">{{text.cancel}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    import {formWrapperMixin} from "@/views/mixins/formwrapper";
    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {userActionsMixin} from "@/views/mixins/userActions";

    import Form from "@/components/form/form";

    export default {
        name: 'AdminUserEdit',
        components: {Form},
        mixins: [formWrapperMixin, formatContentMixin, userActionsMixin],
        data() {
            return {
                userData: [],
                contentStatus: true,
                formData: [
                    {
                        id: 1,
                        type: 'text',
                        name: 'name',
                        label: 'Name',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'string',
                            required: true,
                            min: 5,
                        }
                    },
                    {
                        id: 2,
                        type: 'text',
                        name: 'email',
                        label: 'Email',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'email',
                            required: true,
                        },
                        readonly : true,
                    },
                    {
                        id: 3,
                        type: 'select',
                        name: `roles`,
                        label: 'User type',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'select',
                            required: true
                        },
                        options: []
                    },
                    {
                        id: 4,
                        type: 'select',
                        name: `businessUnitIDs`,
                        label: 'Departments',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'select',
                            required: function(){
                                return this.getFormDataValueByName(this.formData, "roles") === "User";
                            }.bind(this),
                        },
                        options: []
                    },
                ]
            }
        },
        computed: {
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            }
        },
        methods: {
            async loadUserData(){
                await this.loadUserByID(this.id);

                this.setFormDataValueByName(this.formData, "name", this.userData.name);
                this.setFormDataValueByName(this.formData, "email", this.userData.email);
                (Array.isArray(this.userData.roles) && this.userData.roles.length > 0) ? this.setFormDataValueByName(this.formData, "roles", this.userData.roles[0]) : this.setFormDataValueByName(this.formData, "roles", "User");

                if(Array.isArray(this.userData.assignedBusinessUnits) && this.userData.assignedBusinessUnits.length > 0) {
                    this.setFormDataValueByName(this.formData, "businessUnitIDs", this.userData.assignedBusinessUnits[0].id);
                }
            },
            async save(e){
                e.preventDefault();

                log(["Save triggered by wrapper"]);

                if(this.$refs.form.save()){

                    // save updated value into helpData
                    this.userData.name = this.getFormDataValueByName(this.formData, "name");
                    this.userData.email =  this.getFormDataValueByName(this.formData, "email");


                    this.userData.roles = this.getFormDataValueByName(this.formData, "roles") !== "User" ? [this.getFormDataValueByName(this.formData, "roles")] : [];

                    if(this.getFormDataValueByName(this.formData, "roles") === 'Admin'){
                        this.userData.allBusinessUnits = true;
                    }
                    else
                    {
                        this.userData.assignedBusinessUnits = [{id: this.getFormDataValueByName(this.formData, "businessUnitIDs")}]
                    }

                    log(["Form data message", this.userData]);

                    let result = await this.saveUserByID(this.userData.id, this.userData);

                    if(result.status === 200){
                        this.$router.push( { name: 'AdminUserList', params: { message: 'saved'} } );
                    }
                    else
                    {
                        log(["Form save result no ok"]);
                    }
                }
                else
                {
                    // TO DO SHOW ERROR
                    log(["Form is NOT ok to be saved"]);
                }
            },
        },
        async mounted() {
            await this.initBusinessUnits();
            await this.initUserRoles();
            await this.loadUserData();
        },

    }
</script>