<template>
    <div class="columns">
        <div class="content" v-if="contentStatus">
            <div class="panel panel-body">
                <Form v-model="formData" ref="form" />
                <a href="#" :title="text.save" class="btn btn-primary btn-smyspace" @click="save($event)" @keyup.enter="save($event)">{{text.save}}</a> <router-link :to="{ name: 'AdminInPageHelp' }" :title="text.cancel" class="btn btn-tertiary btn-noyspace">{{text.cancel}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";
    import {getHelpByID,saveHelpByID} from "@/shared/dataservice";

    import Form from "@/components/form/form";

    export default {
        name: 'InPageHelpEdit',
        components: {Form},
        mixins: [formWrapperMixin],
        data() {
            return {
                helpData: [],
                contentStatus: true,
                formData: [
                                {
                                    id: 1,
                                    type: 'wysiwyg',
                                    name: 'help',
                                    label: 'Help text',
                                    placeholder: "",
                                    tooltip: "",
                                    value : "",
                                    config : {
                                        buttons : [
                                            "b", "i", "a" /*, "ul", "ol"*/
                                        ]
                                    },
                                    /*validate : {
                                        type: 'string',
                                        required: true,
                                        min: 5,
                                        max: 50
                                    }*/
                                },
                            ]

            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang
            },
            id() {
                return this.$route.params.id;
            }
        },
        async created() {
            let result = await this.loadPageHelpData(this.id);

            log(["Result of page data load", result]);

            if(result.status === 200){
                this.helpData = result.data.data;
                this.formData.find(row => row.name === 'help').value = result.data.data.body;

                // Set Page Title
                this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.helpData.name) });
            }
            else
            {
                // hide page content
                this.contentStatus = false;

                // Set Page Title
                this.setPageTitleAction({ title : this.text.content_404 });
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async loadPageHelpData(id){
                //log(["Loading page help data", id]);
                return getHelpByID(id);
            },
            async save(e){
                e.preventDefault();

                log(["Save triggered by wrapper"]);

                if(this.$refs.form.save()){

                    // save updated value into helpData
                    this.helpData.body = this.getFormDataValueByName(this.formData, "help");

                    log(["Form data help", this.helpData.body]);

                    let result = await saveHelpByID(this.id, this.helpData);

                    if(result.status === 200){
                        this.$router.push( { name: 'AdminInPageHelpList', params: { message: 'saved'} } );
                    }
                    else
                    {
                        log(["Form save result no ok"]);
                    }
                }
                else
                {
                    // TO DO SHOW ERROR
                    log(["Form is NOT ok to be saved"]);
                }
            }
        }
    }
</script>