<template>
    <div class="columns">
        <div class="content body-content" v-if="contentStatus">
            <div class="panel panel-body">
                <Form v-model="formData" ref="form" />
                <a href="#" :title="text.save" class="btn btn-primary btn-noyspace" @click="save($event)" @keyup.enter="save($event)">{{text.save}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {log, showDefaultServerErrorMessage} from "@/shared/utils";
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";
    import lang from "@/shared/lang";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";
    import {getFAQs,saveFAQs} from "@/shared/dataservice";

    import Form from "@/components/form/form";

    export default {
        name: 'FAQs',
        components: {Form},
        mixins: [formWrapperMixin],
        data() {
            return {
                faqData: [],
                contentStatus: true,
                formData: [
                    {
                        id: 1,
                        type: 'wysiwyg',
                        name: 'faqs',
                        label: 'FAQs text',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        config : {
                            buttons : [
                                "b", "i", "a", "h" , "ul", "ol"
                            ]
                        },
                        validate : {
                            type: 'string',
                            required: true
                        }
                    },
                ]

            }
        },
        computed: {
            text() {
                return lang
            },
        },
        async created() {
            let result = await this.loadFAQData();

            log(["Result of page data load", result]);

            if(result.status === 200){
                this.faqData = result.data.data;
                this.formData.find(row => row.name === 'faqs').value = result.data.data.body;
            }
            else
            {
                // hide page content
                this.contentStatus = false;
            }
        },
        methods: {
            async loadFAQData(){
                log(["Loading FAQ data"]);
                return getFAQs();
            },
            save(e){
                e.preventDefault();

                log(["Save triggered by wrapper"]);

                if(this.$refs.form.save()){

                    // save updated value into helpData
                    this.faqData.body = this.getFormDataValueByName(this.formData, "faqs");

                    log(["Form data faq", this.faqData.body]);

                    let result = saveFAQs(this.faqData);

                    if(result){
                        this.$store.dispatch('addSystemMessageAction', {
                            'type' : MESSAGE_TYPE_SUCCESS,
                            'message' : lang.message_content_saved
                        });
                    }
                    else
                    {
                        showDefaultServerErrorMessage("Save FAQs");
                    }
                }
                else
                {
                    // TO DO SHOW ERROR
                    log(["Form is NOT ok to be saved"]);
                }
            }
        }
    }
</script>